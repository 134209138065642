import React, {useState, useEffect, CSSProperties } from 'react';

// reactstrap components
import {
    Button,
    Card,
    CardHeader,
    CardBody,
    NavItem,
    Nav,
    Table,
    Container,
    Row,
    Col,
} from "reactstrap";

import { usePubNub } from 'pubnub-react';
import Chart from "react-apexcharts";

import CustomerHeader from "../../components/Headers/CustomerHeader";
import { useHistory } from 'react-router-dom';
import helper from '../../services/helper.service';
import customerApi from '../../services/customer.service';
import session from '../../services/session.service';

import BounceLoader from "react-spinners/BounceLoader";

global.sortMode = "byRound";

const override:CSSProperties  = {
    display: "block",
    margin: "0 auto",
    borderColor: "red",
};

function HistoryList(){
    const history = useHistory();
    const [clubId, setClubId] = useState(session.get('clubInfo').clubId);
    const [clubName, setClubName] = useState(session.get('clubInfo').clubName);

    const [golferList, setGolferList] = useState([]);
	const [golferCount, setGolferCount] = useState(0);

    let [loading, setLoading] = useState(false);
    let [color, setColor] = useState("#ffffff");

    const [isRead, setIsRead] = useState(false);

    const [filter, setFilter] = useState({
        period: 30,
        holeNum: 1,
        checkHalf: "front" // "back", "turn"
    });

    useEffect(() => {
        if(!isRead){
            setIsRead(true);
            getReportSummary(clubId, 30);
        }
    })

    const getReportSummary = (clubId, period) => {
        setLoading(true);
        customerApi.getReportSummary({club_id: clubId, period: period})
            .then((response) => {
                console.log("getReportSummary Response: ");
                console.log(response);
                setLoading(false);

                let tempGolferList = response.data;
                setGolferCount(tempGolferList.length);

                if(response.status === 200){

                    if(global.sortMode == "byRound"){
                        sortByRound(tempGolferList)
                    }else if(global.sortMode === "byRoundDecrease"){
                        sortByRoundDecrease(tempGolferList);
                    
                    }	else if(global.sortMode === "byBack"){
                        sortByBack(tempGolferList);
                    
                    }else if(global.sortMode === "byBackDecrease"){
                        sortByBackDecrease(tempGolferList);
                    } else if(global.sortMode === "byFront"){
                        sortByFront(tempGolferList);
                    } else if(global.sortMode === "byFrontDecrease"){
                        sortByFrontDecrease(tempGolferList);
                    }else if(global.sortMode === "byName"){
                        sortByName(tempGolferList);
                    } else {
                        // byPace
                        sortByNameDecrease(tempGolferList);
                    }
                }
            })
    }

    const handleClickPeriodFilter = (period) => {
        
        if(!loading){
            setFilter({...filter, period: period});
            getReportSummary(clubId, period);
        }
    }

    const onSortByRound = () => {
        console.log("sort mode: ", global.sortMode);
		if(global.sortMode === 'byRound'){
			global.sortMode = 'byRoundDecrease';
			sortByRoundDecrease(golferList);
		} else {
			global.sortMode = "byRound";
			sortByRound(golferList);
		}
    }

    const sortByRound = (newArray) => {
        console.log("Sort by Round");
		const tempArray = [...newArray].sort((a, b) => {
			const averageA = a.round_average; // ignore upper and lowercase
			const averageB = b.round_average; // ignore upper and lowercase
			if (averageA > averageB) {
				return -1;
			}
			if (averageA < averageB) {
				return 1;
			}

			// names must be equal
			return 0;
		});
		setGolferList(tempArray);
    }

    const sortByRoundDecrease = (newArray) => {
        console.log("Sort by Round");
		const tempArray = [...newArray].sort((a, b) => {
			const averageA = a.round_average; // ignore upper and lowercase
			const averageB = b.round_average; // ignore upper and lowercase
			if (averageA < averageB) {
				return -1;
			}
			if (averageA > averageB) {
				return 1;
			}

			// names must be equal
			return 0;
		});
		setGolferList(tempArray);
    }

    const onSortByBack = () => {
        console.log("sort mode: ", global.sortMode);
		if(global.sortMode === 'byBack'){
			global.sortMode = 'byBackDecrease';
			sortByBackDecrease(golferList);
		} else {
			global.sortMode = "byBack";
			sortByBack(golferList);
		}
    }

    const sortByBack = (newArray) => {
        console.log("Sort by Round");
		const tempArray = [...newArray].sort((a, b) => {
			const averageA = a.back_average; // ignore upper and lowercase
			const averageB = b.back_average; // ignore upper and lowercase
			if (averageA < averageB) {
				return -1;
			}
			if (averageA > averageB) {
				return 1;
			}

			// names must be equal
			return 0;
		});
		setGolferList(tempArray);
    }

    const sortByBackDecrease = (newArray) => {
        console.log("Sort by Round");
		const tempArray = [...newArray].sort((a, b) => {
			const averageA = a.back_average; // ignore upper and lowercase
			const averageB = b.back_average; // ignore upper and lowercase
			if (averageA > averageB) {
				return -1;
			}
			if (averageA < averageB) {
				return 1;
			}

			// names must be equal
			return 0;
		});
		setGolferList(tempArray);
    }

    const onSortByFront = () => {
        console.log("sort mode: ", global.sortMode);
		if(global.sortMode === 'byFront'){
			global.sortMode = 'byFrontDecrease';
			sortByFrontDecrease(golferList);
		} else {
			global.sortMode = "byFront";
			sortByFront(golferList);
		}
    }

    const sortByFront = (newArray) => {
        console.log("Sort by Round");
		const tempArray = [...newArray].sort((a, b) => {
			const averageA = a.front_average; // ignore upper and lowercase
			const averageB = b.front_average; // ignore upper and lowercase
			if (averageA < averageB) {
				return -1;
			}
			if (averageA > averageB) {
				return 1;
			}

			// names must be equal
			return 0;
		});
		setGolferList(tempArray);
    }

    const sortByFrontDecrease = (newArray) => {
        console.log("Sort by Round");
		const tempArray = [...newArray].sort((a, b) => {
			const averageA = a.front_average; // ignore upper and lowercase
			const averageB = b.front_average; // ignore upper and lowercase
			if (averageA > averageB) {
				return -1;
			}
			if (averageA < averageB) {
				return 1;
			}

			// names must be equal
			return 0;
		});
		setGolferList(tempArray);
    }

    const onSortByName = () => {
        console.log("sort mode: ", global.sortMode);
		if(global.sortMode === 'byName'){
			global.sortMode = 'byNameDecrease';
			sortByNameDecrease(golferList);
		} else {
			global.sortMode = "byName";
			sortByName(golferList);
		}
    }

    const sortByName = (newArray) => {
        console.log("Sort by Round");
		const tempArray = [...newArray].sort((a, b) => {
			const averageA = a.golfer_name; // ignore upper and lowercase
			const averageB = b.golfer_name; // ignore upper and lowercase
			if (averageA < averageB) {
				return -1;
			}
			if (averageA > averageB) {
				return 1;
			}

			// names must be equal
			return 0;
		});
		setGolferList(tempArray);
    }

    const sortByNameDecrease = (newArray) => {
        console.log("Sort by Round");
		const tempArray = [...newArray].sort((a, b) => {
			const averageA = a.golfer_name; // ignore upper and lowercase
			const averageB = b.golfer_name; // ignore upper and lowercase
			if (averageA > averageB) {
				return -1;
			}
			if (averageA < averageB) {
				return 1;
			}

			// names must be equal
			return 0;
		});
		setGolferList(tempArray);
    }

    const onSortByRoundCount = () => {
        console.log("sort mode: ", global.sortMode);
		if(global.sortMode === 'byRoundCount'){
			global.sortMode = 'byRoundCountDecrease';
			sortByRoundCountDecrease(golferList);
		} else {
			global.sortMode = "byRoundCount";
			sortByRoundCount(golferList);
		}
    }

    const sortByRoundCount = (newArray) => {
        console.log("Sort by Round Count");
		const tempArray = [...newArray].sort((a, b) => {
			const averageA = a.round_count; // ignore upper and lowercase
			const averageB = b.round_count; // ignore upper and lowercase
			if (averageA > averageB) {
				return -1;
			}
			if (averageA < averageB) {
				return 1;
			}

			// names must be equal
			return 0;
		});
		setGolferList(tempArray);
    }

    const sortByRoundCountDecrease = (newArray) => {
        console.log("Sort by Round");
		const tempArray = [...newArray].sort((a, b) => {
			const averageA = a.round_count; // ignore upper and lowercase
			const averageB = b.round_count; // ignore upper and lowercase
			if (averageA < averageB) {
				return -1;
			}
			if (averageA > averageB) {
				return 1;
			}

			// names must be equal
			return 0;
		});
		setGolferList(tempArray);
    }

    const onSortByFrontCount = () => {
        console.log("sort mode: ", global.sortMode);
		if(global.sortMode === 'byFrontCount'){
			global.sortMode = 'byFrontCountDecrease';
			sortByFrontCountDecrease(golferList);
		} else {
			global.sortMode = "byFrontCount";
			sortByFrontCount(golferList);
		}
    }

    const sortByFrontCount = (newArray) => {
        console.log("Sort by Round Count");
		const tempArray = [...newArray].sort((a, b) => {
			const averageA = a.front_count; // ignore upper and lowercase
			const averageB = b.front_count; // ignore upper and lowercase
			if (averageA > averageB) {
				return -1;
			}
			if (averageA < averageB) {
				return 1;
			}

			// names must be equal
			return 0;
		});
		setGolferList(tempArray);
    }

    const sortByFrontCountDecrease = (newArray) => {
        console.log("Sort by Round");
		const tempArray = [...newArray].sort((a, b) => {
			const averageA = a.front_count; // ignore upper and lowercase
			const averageB = b.front_count; // ignore upper and lowercase
			if (averageA < averageB) {
				return -1;
			}
			if (averageA > averageB) {
				return 1;
			}

			// names must be equal
			return 0;
		});
		setGolferList(tempArray);
    }

    const onSortByBackCount = () => {
        console.log("sort mode: ", global.sortMode);
		if(global.sortMode === 'byBackCount'){
			global.sortMode = 'byBackCountDecrease';
			sortByBackCountDecrease(golferList);
		} else {
			global.sortMode = "byBackCount";
			sortByBackCount(golferList);
		}
    }

    const sortByBackCount = (newArray) => {
        console.log("Sort by Round Count");
		const tempArray = [...newArray].sort((a, b) => {
			const averageA = a.back_count; // ignore upper and lowercase
			const averageB = b.back_count; // ignore upper and lowercase
			if (averageA > averageB) {
				return -1;
			}
			if (averageA < averageB) {
				return 1;
			}

			// names must be equal
			return 0;
		});
		setGolferList(tempArray);
    }

    const sortByBackCountDecrease = (newArray) => {
        console.log("Sort by Round");
		const tempArray = [...newArray].sort((a, b) => {
			const averageA = a.back_count; // ignore upper and lowercase
			const averageB = b.back_count; // ignore upper and lowercase
			if (averageA < averageB) {
				return -1;
			}
			if (averageA > averageB) {
				return 1;
			}

			// names must be equal
			return 0;
		});
		setGolferList(tempArray);
    }
    const onSortByTurn = () => {
        console.log("sort mode: ", global.sortMode);
		if(global.sortMode === 'byTurn'){
			global.sortMode = 'byTurnDecrease';
			sortByTurnDecrease(golferList);
		} else {
			global.sortMode = "byTurn";
			sortByTurn(golferList);
		}
    }

    const sortByTurn = (newArray) => {
        console.log("Sort by Round Count");
		const tempArray = [...newArray].sort((a, b) => {
			const averageA = a.turn_average; // ignore upper and lowercase
			const averageB = b.turn_average; // ignore upper and lowercase
			if (averageA > averageB) {
				return -1;
			}
			if (averageA < averageB) {
				return 1;
			}

			// names must be equal
			return 0;
		});
		setGolferList(tempArray);
    }

    const sortByTurnDecrease = (newArray) => {
        console.log("Sort by Round");
		const tempArray = [...newArray].sort((a, b) => {
			const averageA = a.turn_average; // ignore upper and lowercase
			const averageB = b.turn_average; // ignore upper and lowercase
			if (averageA < averageB) {
				return -1;
			}
			if (averageA > averageB) {
				return 1;
			}

			// names must be equal
			return 0;
		});
		setGolferList(tempArray);
    }


    const onDetail = (e) => {
        const index = e.target.id;
		console.log("Edit index: ", index);

        const selectedGolfer = golferList[index];
        console.log("selected Golfer; ", selectedGolfer);
        history.push('/customer/historyanalysis', {golfer: selectedGolfer});
    }

    return (
        <>
            <CustomerHeader/>
            
            <Container className="mt--7" fluid >
            
                <Col>

                    <Card className="bg-default shadow">
                        <CardHeader className="bg-transparent border-0">
                            <BounceLoader color="#36d7b7" loading={loading} cssOverride={override} size={40} />
                            <Row className="align-items-center">

                                <div className="col text-right">
                                    <h3 className="text-success mb-0"></h3>
                                </div>
                        
                                <div className="col">
                                    <h2 className="text-white mb-0 text-center">{clubName}</h2>
                                </div>


                                <div className="col text-right">
                                    <Button
                                        color={filter.period === 30 ? "success" : "info"}
                                
                                        onClick={(e) => handleClickPeriodFilter(30)}
                                    
                                        size="sm"
                                        // id={index}
                                    >
                                    
                                        30 Days
                                    </Button>
                                    <Button
                                        color={filter.period === 90 ? "success" : "info"}
                                
                                        onClick={(e) => handleClickPeriodFilter(90)}
                                    
                                        size="sm"
                                        // id={index}
                                    >
                                    
                                        90 Days
                                    </Button>
                                    <Button
                                        color={filter.period === 365 ? "success" : "info"}
                                
                                        onClick={(e) => handleClickPeriodFilter(365)}
                                    
                                        size="sm"
                                        // id={index}
                                    >
                                    
                                        Cal Year
                                    </Button>
                                </div>
                            
                            </Row>
                        </CardHeader>
                        <CardBody>
                            <Table
                                className="align-items-center table-dark table-flush"
                                responsive
                            >
                                <thead className="thead-dark">
                                    <tr>
     
                                        <th onClick={() => onSortByName()}>
                                            <div className="text-white mb-0 text-center">Golfer</div>
                                            <div className="text-white mb-0 text-center">Name <i className="fas fa-sort"></i></div>
                                        </th>
                                        <th onClick={() => onSortByFront()} >
                                            <div className="text-white mb-0 text-center">Average</div>
                                            <div className="text-white mb-0 text-center">Front 9 <i className="fas fa-sort"></i></div>
                                        </th>
                                        <th onClick={() => onSortByFrontCount()} >
                                            <div className="text-white mb-0 text-center"># of</div>
                                            <div className="text-white mb-0 text-center">Front 9 <i className="fas fa-sort"></i></div>
                                        </th>
                                        <th onClick = {() => onSortByBack()}>
                                            <div className="text-white mb-0 text-center">Average</div>
                                            <div className="text-white mb-0 text-center">Back 9 <i className="fas fa-sort"></i></div>
                                        </th>
                                        <th onClick = {() => onSortByBackCount()}>
                                            <div className="text-white mb-0 text-center"># of</div>
                                            <div className="text-white mb-0 text-center">Back 9 <i className="fas fa-sort"></i></div>
                                        </th>
                                        <th onClick = {() => onSortByTurn()}>
                                            <div className="text-white mb-0 text-center">Average</div>
                                            <div className="text-white mb-0 text-center">Turn <i className="fas fa-sort"></i></div>
                                        </th>
                                        <th onClick = {() => onSortByRound()}>
                                            <div className="text-white mb-0 text-center">Average</div>
                                            <div className="text-white mb-0 text-center"> 18 holes <i className="fas fa-sort"></i></div>
                                        </th>
                                        
                                        <th onClick = {() => onSortByRoundCount()}>
                                            <div className="text-white mb-0 text-center"># of</div>
                                            <div className="text-white mb-0 text-center"> 18 holes <i className="fas fa-sort"></i></div>
                                        </th>
                                        <th >
                                            <div className="text-white mb-0">Action</div>
                                        </th>
 
                                    </tr>
                                </thead>

                                <tbody>
                                {
                                    golferList.length > 0 ?
                                        golferList.map((item, index) =>
                                            <tr key={index}>
                                                <td>
                                                    <div className="text-white mb-0 text-center">
                                                        {item.golfer_name}
                                                    </div>
                                                </td>
                                               
                                                <td>
                                                    <div className="text-white mb-0 text-center">
                                                        {item.front_average}
                                                    </div>
                                                </td>
                                                <td>
                                                    <div className="text-white mb-0 text-center">
                                                        {item.front_count}
                                                    </div>
                                                </td>
                                                <td>
                                                    <div className="text-white mb-0 text-center">
                                                        {item.back_average}
                                                    </div>
                                                </td>
                                                <td>
                                                    <div className="text-white mb-0 text-center">
                                                        {item.back_count}
                                                    </div>
                                                </td>
                                                <td>
                                                    <div className="text-white mb-0 text-center">
                                                        {item.turn_average}
                                                    </div>
                                                </td>
                                                <td>
                                                    <div className="text-white mb-0 text-center">
                                                        {item.round_average}
                                                    </div>
                                                </td>

                                                <td>
                                                    <div className="text-white mb-0 text-center">
                                                        {item.round_count}
                                                    </div>
                                                </td>
                                                
                       
                                                <td className=" align-items-center">
                                                    <Button
                                                        color="success"
                                                        size="sm"
                                                        id={index}
                                                        onClick={(e) => onDetail(e)}
                                                    >
                                                        Detail
                                                    </Button>
                                                </td>
                                            </tr>
                                            )
                                    :
                                    <></>
                                }
                                </tbody>
                            </Table>
                        </CardBody>
                    </Card>
                </Col>
            </Container>
        </>
    )
}

export default HistoryList;