import React, {useState, useEffect} from "react";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";

import AuthLayout from '../layouts/Auth.js';
import AdminLayout from "../layouts/Admin.js";
import CustomerLayout from '../layouts/Customer';
import MapLayout from '../layouts/Map';
import AccuRangeMapLayout from '../layouts/AccuRangeMap';

import session from '../services/session.service';
import { useHistory } from "react-router-dom";

import  { Analytics, Auth, Amplify } from "aws-amplify";
import awsmobile from "./aws-exports";

Amplify.configure(awsmobile);

function App(props){

    const [isLogin, setIsLogin] = useState(session.get('isLogin') || false);
    const [isAdmin, setIsAdmin] = useState(session.get('isAdmin') || false);
    const [isMap, setIsMap] = useState(window.location.href.includes('map') || false);
    const [isAccuRangMap, setIsAccuRangMap]  = useState(window.location.href.includes('accurange') || false);

    useEffect(() => {
        setIsLogin(session.get('isLogin') || false);
        setIsAdmin(session.get('isAdmin'));
        
        console.log("isLogin: ", isLogin);
        console.log("isAdmin: ", isAdmin);
    }, [isLogin, isAdmin]);
    return(

        <Switch>
    
        {
            isMap? 
            <Route path="/" render = {props => <MapLayout {...props} />} />
            :
            isAccuRangMap ?
            <Route path="/" render = {props => <AccuRangeMapLayout {...props} />} />
            :
            !isLogin ? 
                <Route path="/" render={(props) => <AuthLayout {...props} />} />
                :
                !isAdmin ?
                    <Route path="/" render={(props) => <CustomerLayout {...props} />} />
                    :
                    <Route path="/" render={(props) => <AdminLayout {...props} />} />

        }
      
      </Switch>

  
    )
}

export default App;