import { Store } from 'react-notifications-component';
import 'react-notifications-component/dist/theme.css';
import 'animate.css/animate.min.css';

export default {
    showToast: function(title, message, type_code) {
        let type = 'default';
        
        switch(type_code) {
            case 1 : 
                type = 'success';
                break;
            case 2 : 
                type = 'warning';
                break;
            case 3 : 
                type = 'danger';
                break;
            case 4 : 
                type = 'info';
                break;
            default : 
                type = 'default';
                break;
        }
        
        Store.addNotification({
            title: title,
            message: message,
            type: type,                         // 'default', 'success', 'info', 'warning'
            container: 'top-right',                // where to position the notifications
            animationIn: ["animate__animated", "animate__fadeIn"],
            animationOut: ["animate__animated", "animate__fadeOut"], // animate.css classes that's applied
            dismiss: {
              duration: 3000
            }
        });

       
    }
}