import React, {useState, useEffect, useCallback} from 'react';
// node.js library that concatenates classes (strings)

// reactstrap components
import {

  Card,
  CardHeader,
  Table,
  Container,
  Row,
  Col,

} from "reactstrap";

import { useHistory } from 'react-router-dom';
import { usePubNub } from 'pubnub-react';

import CustomerHeader from "../../components/Headers/CustomerHeader";
import session from '../../services/session.service';

import helper from '../../services/helper.service';
import customerApi from '../../services/customer.service';
import GoogleMapReact from 'google-map-react';
import Marker from '../../components/Markers/Marker'

const containerStyle = {
    width: '100%',
    height: '1024px'
};

const center = {
    lat: 42.8519,
    lng: -86.1322
};

var center1 = {
    lat: 42.8529,
    lng: -86.1342
};

var lat1 = 42.8529;
var lng1 = -86.1342;

const markerList = [
    
    {
        lat: 42.8519,
        lng: -86.1322,
        color: "red",
        name: "2"
    },
    {
        lat: 42.8529,
        lng: -86.1342,
        color: "blue",
        name: "3"
    }
]


function  DisplayMap(props){

    const [clubId, setClubId] = useState(session.get('clubInfo').clubId);
    const [clubName, setClubName] = useState(session.get('clubInfo').clubName);
    const [centerLati, setCenterLati] = useState(session.get("clubInfo").latitude);
    const [centerLong, setCenterLong] = useState(session.get("clubInfo").longitude);

    const [isRead, setIsRead] = useState(false);

    const [udid, setUdid] = useState("");

    const [startTime, setStartTime] = useState("");
    const [lastBoxNumber, setLastBoxNumber] = useState("");
    const [cumulatedTime, setCumulatedTime] = useState("");
    const [playedHoleCount, setPlayedHoleCount] = useState("");
    const [timeOnCourse, setTimeOnCourse] = useState("");
    const [playMode, setPlayMode] = useState(1);

    const [recordList, setRecordList] = useState([]);
    const [golferList, setGolferList] = useState([]);
    const pubnub = usePubNub();

    const [lati, setLati] = useState(lat1);
    const [long, setLong] = useState(lng1);
    
    const getMapOptions = (maps) => {
        return {
            // disableDefaultUI: true,
            // mapTypeControl: true,
            // streetViewControl: true,
            mapTypeId: maps.MapTypeId.SATELLITE,
            // heading: -180,
            rotateControl: true,
        };
    };

    const handleApiLoaded = (map, maps) => {
        // use map and maps objects
        console.log("map loaded::");
        // map.setHeading(200);
      };

    useEffect(() => {

		// We need to make sure that PubNub is defined

		if (pubnub) {
		  	// Set the UUID of our user

            const { state } = props.location;
            // const golfer = state.golfer;

		  	// pubnub.setUUID(golfer.udid);

			pubnub.subscribe({ channels: ["refresh"] });

            const listener = {
                message: envelope => {
                    
                    const { message, publisher } = envelope;
                    console.log("pubnub message: ", message, publisher);
                    // if(publisher == golfer.golfer_id){
                       
                    // }
                    
                }
            }

            pubnub.addListener(listener);

			return () => {
				pubnub.unsubscribeAll();
			};

		};

	}, [pubnub]);

    useEffect(() => {
       
        const { state } = props.location;
        const clubId = state.clubId;
        console.log("club Id: ", clubId);
       
    })

    useEffect(() => {

        const interval = setInterval(() => {
			processGolferList();
		}, 1500);
		return () => clearInterval(interval);
	}, []);

    // const { isLoaded } = useJsApiLoader({
    //     id: 'google-map-script',
    //     googleMapsApiKey: "AIzaSyCk2rpWi4waVXWK-zBmstyi7JJOlHinwI8"
    // })
    const convertSecondsToHour = (d) => {
		d = Number(d);
		var h = Math.floor(d / 3600);
		var m = Math.floor(d % 3600 / 60);
		var s = Math.floor(d % 3600 % 60);
	
		var hDisplay =h > 9 ? h.toString() : "0" + h.toString();
		var mDisplay = m > 9 ? m.toString() : "0" + m.toString();
		
		return hDisplay + ":" + mDisplay; 
	}
    const processGolferList = () => {
        customerApi.getGolferList({club_id: clubId})
			.then((response) => {
				console.log("getGolferList Response: ");
				console.log(response);

				if(response.status === 200){

				let tempGolferList = [];
				let i = 0;
				response.data.map((item) => {
					i++;
                    let cumulatedTime = Number(item.cumulated_time);
                    console.log("cumulated time: ", cumulatedTime);
                    let lastLocation = item.last_location;
                    let timeSinceLastHole = "";
                    if(item.played_hole_count == 18 || item.seconds == ""){
                        timeSinceLastHole = "";
                    } else {
                        timeSinceLastHole = convertSecondsToHour(Number(item.seconds)); 
                    }

                    let name = "";
                    if(item.device_type == ""){
                        const tempName = "G" + item.udid.substring(8, 9).toUpperCase();
                        name = tempName;
                        console.log("tempName: ", tempName);
                    }else{
                        const fullName = item.device_type.split(' ');
                        const tempName = fullName.shift().charAt(0) + fullName.pop().charAt(0);
                        console.log("tempName: ", tempName);
                        name = tempName;
                    }
					let newItem = {
						order :  name,
						color: item.status == 0 ? "gray" : cumulatedTime >= 0 ? "green" : cumulatedTime > -5 ? "yellow" : "red",
                        lat: Number(lastLocation.lati),
                        lng: Number(lastLocation.long),
                        currentPace: item.cumulated_time,
						device_type: item.device_type,
						golfer_id: item.golfer_id,
						last_hole_number: item.last_hole_number,
						play_mode: item.play_mode,
						played_hole_count: item.played_hole_count,
						seconds: item.seconds,
						start_hole: item.start_hole,
						start_time: item.start_time,
						time_on_course: item.time_on_course,
						time_since_last: timeSinceLastHole,
						name: item.udid,
						last_location: item.last_location,
                        status: item.status
					}

					tempGolferList.push(newItem);
				});

				global.golferList = tempGolferList;

                setGolferList(tempGolferList);
			
				} else {
					helper.showToast("Error", "Failed to get Hole Time List", 3);
				}
			})
			.catch((error) => {
					console.log("err: ", error);
					helper.showToast("Error", "Invaid auth", 3);
			})
    }

    return (
        <>
        <CustomerHeader />
        {/* Page content */}
        <Container className="mt--7" fluid >
            {/* <Row> */}
            <div style={{ height: '100vh', width: '100%' }}>
                <GoogleMapReact
                    bootstrapURLKeys={{ key: 'AIzaSyCk2rpWi4waVXWK-zBmstyi7JJOlHinwI8' }}
                    yesIWantToUseGoogleMapApiInternals
                    defaultCenter={{
                        lat: centerLati,
                        lng: centerLong
                    }}
                    defaultZoom={16}
                    options={getMapOptions}
                    onGoogleApiLoaded={({ map, maps }) => handleApiLoaded(map, maps)}
                >
                    {
                        golferList.length > 0 ?

                        golferList.map((item) => {
                            return (
                         
                                <Marker
                                    lat={item.lat}
                                    lng={item.lng}
                                    name={item.name}
                                    order={item.order}
                                    color={item.color}
                                    startTime = {item.start_time}
                                    startHole = {item.start_hole}
                                    lastHole = {item.last_hole_number}
                                    playedCount = {item.played_hole_count}
                                    currentPace = {item.currentPace}
                                    timeOnCourse = {item.time_on_course} 
                                    timeOnSince = {item.time_since_last}
		
                                    showInfo={false}
                   
                                />
                   
                     
                            )
                        })
                        :
                        <></>
                    }
               
                </GoogleMapReact>
            </div>
           
              
            {/* </Row> */}
        
        </Container>

        </>
    )
};

export default DisplayMap;