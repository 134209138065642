import React, {useState, useEffect, useCallback} from 'react';
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  Container,
  Row,
  Col,
} from "reactstrap";

import { useHistory } from 'react-router-dom';
import Select from 'react-select'

// core components
import AddClubHeader from "../../components/Headers/AddClubHeader.js";
import environments from "../../environments";

import helper from '../../services/helper.service';
import adminApi from '../../services/admin.service';
  
  function EditClub (props)  {

    const history = useHistory();

    const [bannerImage, setBannerImage] = useState("");
    const [photoUrl, setPhotoUrl] = useState("");
    let bannerFile = React.createRef(null);
    const [clubId, setClubId] = useState(0);
    const [clubName, setClubName] = useState("");
    const [email, setEmail] = useState("");
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const [latitude, setLatitude] = useState("");
    const [longitude, setLongitude] = useState("");
    const [heading, setHeading] = useState("");
    const [radius, setRadius] = useState("");

    const [latiCenter, setLatiCenter] = useState("");
    const [longCenter, setLongCenter] = useState("");
    const [radiusCenter, setRadiusCenter] = useState("");

    const [isRead, setIsRead] = useState(false);

    const options = [
      { value: "-4", label: 'UTC -4' },
      { value: "-5", label: 'UTC -5' },
      { value: "-6", label: 'UTC -6' },
      { value: "-7", label: 'UTC -7' },
      { value: "-8", label: 'UTC -8' },
      { value: "-9", label: 'UTC -9' }
    ]

    const turnOptions = [
      { value: "true", label: 'Yes' },
      { value: "false", label: 'No' }
    ]

    const groupOptions = [
      { value: "true", label: 'Yes' },
      { value: "false", label: 'No' }
    ]

    const [selectedOption, setSelectedOption] = useState("");
    const [selectedTurnOption, setSelectedTurnOption] = useState(props.location.state.club.turn == turnOptions[0].value ? turnOptions[0] : turnOptions[1]);
    const [selectedMapOption, setSelectedMapOption] = useState(props.location.state.club.map == turnOptions[0].value ? turnOptions[0] : turnOptions[1]);
    const [selectedGroupOption, setSelectedGroupOption] = useState(props.location.state.club.group == groupOptions[0].value ? groupOptions[0] : groupOptions[1]);
    const onselectionchange = (item) => {
      console.log("selected Item", item);
      setSelectedOption(item);
    
    }

    const onTurnSelectionChange = (item) => {
      console.log("selected Item", item);
      setSelectedTurnOption(item);
    }

    const onMapSelectionChange = (item) => {
      console.log("selected Item", item);
      setSelectedMapOption(item);
    }

    const onGroupSelectionChange = (item) => {
      console.log("selected Item", item);
      setSelectedGroupOption(item);
    }

    useEffect(() => {
       
        const { state } = props.location;
        console.log("club: ", state.club);
        const club = state.club;
        if(!isRead){
            setIsRead(true);

            setClubId(club.clubId);
            setClubName(club.name);
            setEmail(club.email);
            setPassword(club.password);
            setUsername(club.username);
            setLatitude(Number(club.latitude));
            setLongitude(Number(club.longitude));
            setHeading(club.heading);
            setRadius(club.radius);
            setBannerImage(club.logoUrl);
            setSelectedOption(
              {label: "UTC " + club.timezone,
              value: Number(club.timezone)});

            setLatiCenter(Number(club.lati_center));
            setLongCenter(Number(club.long_center));
            setRadiusCenter(club.radius_center);
        }
       
    })


    const onBannerImageChange = event => {
        if (event.target.files && event.target.files[0]) {
            let img = event.target.files[0];
            
            setBannerImage(URL.createObjectURL(img));
            setPhotoUrl(img);

            console.log("bannerImage: ", bannerImage);
            console.log("photo_url: ", photoUrl);
          }
    };

    const submitUpdateClub = async() => {

      const updatedClub = {
            club_id: clubId,
            name: clubName,
            email: email,
            username: username,
            password: password,
            latitude: latitude,
            longitude: longitude,
            radius: radius,
            thumbnail: photoUrl,
            timezone: selectedOption.value,
            turn: selectedTurnOption.value,
            heading: heading,

            lati_center: latiCenter,
            long_center: longCenter,
            radius_center: radiusCenter,

            map: selectedMapOption.value,
            group: selectedGroupOption.value
      }

      console.log("updatedClub: ", updatedClub);

      adminApi.updateClub(updatedClub)
          .then((response) => {

          console.log("addClub reponse: ");
          console.log(response);
  
          if(response.status === 200){
              helper.showToast('Success', 'Successfully Added.', 1);
              history.push('/');
  
          }else {
          
              helper.showToast('Error', 'Failed to add new club.', 3);
          }
      })
      .catch((error) => {
          console.log("err: ", error);
    
          helper.showToast('Error', 'Invalid club', 3);
      })
  }
    return (
      <>
        <AddClubHeader/>
        {/* Page content */}
        <Container className="mt--9" fluid>
          <Row>

            <Col className="order-xl-1" xl="12">
              <Card className="bg-secondary shadow">
                <CardHeader className="bg-white border-0">
                  <Row className="align-items-center">
                    <Col xs="8">
                      <h3 className="mb-0">Edit Club</h3>
                    </Col>
                    <Col className="text-right" xs="4">
                      <Button
                        color="primary"
                   
                        onClick={() =>submitUpdateClub()}
                        size="sm"
                      >
                        Update
                      </Button>
                    </Col>
                  </Row>
                </CardHeader>
                <CardBody>
                  <Form>
                    <h6 className="heading-small text-muted mb-4">
                      club information
                    </h6>
                    <div className="pl-lg-4">

                      <Row>
                        <Col lg="6">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-username"
                            >
                              Club Name
                            </label>
                            <Input
                              className="form-control-alternative"
                              defaultValue={clubName}
                              placeholder="Golf Club"
                              onChange={(e) => setClubName(e.target.value)}
                              type="text"
                            />
                          </FormGroup>
                        </Col>
                        <Col lg="6">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-email"
                            >
                              Email address
                            </label>
                            <Input
                              className="form-control-alternative"
                             defaultValue={email}
                              placeholder="jesse@example.com"
                              type="email"
                              onChange={(e) => setEmail(e.target.value)}
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col lg="6">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-first-name"
                            >
                                User name
                            </label>
                            <Input
                              className="form-control-alternative"
                              defaultValue={username}
                              placeholder="accupaceuser"
                              type="text"
                              onChange={(e) => setUsername(e.target.value)}
                            />
                          </FormGroup>
                        </Col>
                        <Col lg="6">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-last-name"
                            >
                              Password
                            </label>
                            <Input
                            defaultValue={password}
                              className="form-control-alternative"
                              id="input-last-name"
                              placeholder=""
                              onChange={(e) => setPassword(e.target.value)}
                          
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                    </div>
                    <hr className="my-4" />
                    {/* Address */}
                    <h6 className="heading-small text-muted mb-4">
                      Location information
                    </h6>
                    <div className="pl-lg-4">

                      <Row>
                        <Col lg="2">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-city"
                            >
                              Latitude
                            </label>
                            <Input
                              className="form-control-alternative"
                           
                              defaultValue={latitude}
                              placeholder="39.234322"
                              type="number"
                              onChange={(e) => setLatitude(e.target.value)}
                            />
                          </FormGroup>
                        </Col>
                        <Col lg="2">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-country"
                            >
                              Longitude
                            </label>
                            <Input
                              className="form-control-alternative"
                                defaultValue={longitude}
                              placeholder="-82.235325"
                              type="number"
                              onChange={(e) => setLongitude(e.target.value)}
                            />
                          </FormGroup>
                        </Col>
                        <Col lg="2">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-country"
                            >
                              Heading
                            </label>
                            <Input
                              className="form-control-alternative"
                                defaultValue={heading}
                              placeholder="90"
                              type="number"
                              onChange={(e) => setHeading(e.target.value)}
                            />
                          </FormGroup>
                        </Col>
                        <Col lg="2">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-country"
                            >
                              Radius (m)
                            </label>
                            <Input
                              className="form-control-alternative"
                                defaultValue={radius}
                              placeholder="10"
                              type="number"
                              onChange={(e) => setRadius(e.target.value)}
                            />
                          </FormGroup>
                        </Col>

                        <Col lg="2">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-country"
                            >
                              Timezone
                            </label>
                         
                            <Select 
                              options={options}
                              defaultValue={selectedOption}
                              onChange={(item)=>onselectionchange(item)} 
                            />
                          </FormGroup>
                        </Col>

                        <Col lg="2">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-country"
                            >
                              Exist Turn?
                            </label>
                     
                            <Select 
                              options={turnOptions}
                              defaultValue={selectedMapOption}
                              onChange={(item)=>onTurnSelectionChange(item)} 
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                    </div>

                    <hr className="my-4" />
                    {/* Address */}
                    <h6 className="heading-small text-muted mb-4">
                    Center information
                    </h6>
                    <div className="pl-lg-4">

                      <Row>
                        <Col lg="2">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-city"
                            >
                              Latitude
                            </label>
                            <Input
                              className="form-control-alternative"
                           
                              defaultValue={latiCenter}
                              placeholder="39.234322"
                              type="number"
                              onChange={(e) => setLatiCenter(e.target.value)}
                            />
                          </FormGroup>
                        </Col>
                        <Col lg="2">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-country"
                            >
                              Longitude
                            </label>
                            <Input
                              className="form-control-alternative"
                                defaultValue={longCenter}
                              placeholder="-82.235325"
                              type="number"
                              onChange={(e) => setLongCenter(e.target.value)}
                            />
                          </FormGroup>
                        </Col>
                    
                        <Col lg="2">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-country"
                            >
                              Radius (m)
                            </label>
                            <Input
                              className="form-control-alternative"
                                defaultValue={radiusCenter}
                              placeholder="40"
                              type="number"
                              onChange={(e) => setRadiusCenter(e.target.value)}
                            />
                          </FormGroup>
                        </Col>

                        <Col lg="2">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-country"
                            >
                              Display Map?
                            </label>
                     
                            <Select 
                              options={turnOptions}
                              defaultValue={selectedMapOption}
                              onChange={(item)=>onMapSelectionChange(item)} 
                            />
                          </FormGroup>
                        </Col>

                        <Col lg="2">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-country"
                            >
                              Exist Group?
                            </label>
                     
                            <Select 
                              options={groupOptions}
                              defaultValue={selectedGroupOption}
                              onChange={(item)=>onGroupSelectionChange(item)} 
                            />
                          </FormGroup>
                        </Col>
             
                      </Row>
                    </div>

                    <hr className="my-4" />
                    {/* Address */}
                    <h6 className="heading-small text-muted mb-4">
                      Logo Upload
                    </h6>
                    <div className="pl-lg-4">

                      <Row>
                  
                     
                         <Col lg="4">   
                        <Input type="file" className="form-control hidden" ref={input => bannerFile = input} onChange={(event) => onBannerImageChange(event)} />
                        
                        </Col>

                    
                        {bannerImage === '' ?
                       <></>
                        :
                        <Col lg="6">   
                        <img className="image img-upload" src={bannerImage} alt="banner image" style={{width: "150px"}} />
                        </Col>
                        }
                        
                      </Row>
                    </div>
                  

                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </>
    );
  };
  
  export default EditClub;
  