import { useState, useEffect } from "react";
// node.js library that concatenates classes (strings)


// reactstrap components
import {
  Badge,
  Card,
  CardHeader,
  CardFooter,
  DropdownMenu,
  DropdownItem,
  Col,
  DropdownToggle,
  Media,
  Input,
  PaginationItem,
  PaginationLink,
  Progress,
  Table,
  Container,
  Row,
  UncontrolledTooltip,
  Button,
  Form
} from "reactstrap";

import { useHistory } from 'react-router-dom';

import AddClubHeader from "../../components/Headers/AddClubHeader.js";

import helper from '../../services/helper.service';
import adminApi from '../../services/admin.service';
import session from '../../services/session.service';

function  EditBox(props){

	const [isEdit, setIsEdit] = useState(false);
	const [isRead, setIsRead] = useState(false);

	const [clubId, setClubId] = useState(0);
	const [clubName, setClubName] = useState("");

	const [boxList, setBoxList] = useState([]);
	const [activeIndex, setActiveIndex] = useState(0);

	const [lati1, setLati1] = useState(0);
	const [long1, setLong1] = useState(0);

	const [lati2, setLati2] = useState(0);
	const [long2, setLong2] = useState(0);

	const [lati3, setLati3] = useState(0);
	const [long3, setLong3] = useState(0);

	const [lati4, setLati4] = useState(0);
	const [long4, setLong4] = useState(0);

	const [file, setFile] = useState();
	const fileReader = new FileReader();

	useEffect(() => {
		
		const { state } = props.location;
		console.log("club: ", state.club);
		const club = state.club;
		if(!isRead){
			setIsRead(true);

			setClubId(club.clubId);
			setClubName(club.name);


			getBoxList(club.clubId);
		
		}
	
	})

	const getBoxList = (clubId) => {
	
		adminApi.getBoxList({club_id: clubId})
		.then((response) => {

		console.log("boxList reponse: ");
		console.log(response);

		if(response.status === 200){

			const tempBoxList = response.data;
			setBoxList(tempBoxList);

		}else {
		
			helper.showToast('Error', 'Failed to get.', 3);
		}
		
		})
		.catch((error) => {
			console.log("err: ", error);
		
			helper.showToast('Error', 'Invalid auth', 3);
		})
	}


	const onEdit = (e) => {

		const index = e.target.id;
		console.log("Edit index: ", index);
		setActiveIndex(index);
		setIsEdit(true);

		const activeBox = boxList[index];

		setLati1(activeBox.lati1);
		setLong1(activeBox.long1);

		setLati2(activeBox.lati2);
		setLong2(activeBox.long2);

		setLati3(activeBox.lati3);
		setLong3(activeBox.long3);

		setLati4(activeBox.lati4);
		setLong4(activeBox.long4);
	}

	const onSave = (e) => {

		const index = e.target.id;
		console.log("Edit index: ", index);


		console.log("Selected Box: ", boxList[activeIndex]);

		const requestItem = {
			box_id: boxList[activeIndex].id,
			lati1: lati1,
			long1: long1,
			lati2: lati2,
			long2: long2,
			lati3: lati3,
			long3: long3,
			lati4: lati4,
			long4: long4
		};

		console.log("requestItem : ", requestItem);

		adminApi.updateBox(requestItem)
			.then((response) => {

			console.log("box reponse: ");
			console.log(response);
	
			if(response.status === 200){
				helper.showToast('Success', 'Successfully Updated.', 1);
				
				setIsEdit(false);
				setIsRead(false);
	
			}else {
			
				helper.showToast('Error', 'Failed to update box.', 3);
			}
		})
		.catch((error) => {
			console.log("err: ", error);
	
			helper.showToast('Error', 'Invalid club', 3);
		})

	
	}

	const onCancel = () => {
		setIsEdit(false);
	}

	

	const handleOnChange = (e) => {
		setFile(e.target.files[0]);
	};

	const handleOnSubmit = (e) => {
		e.preventDefault();

		if (file) {
			fileReader.onload = function (event) {
				const csvOutput = event.target.result;
				console.log(csvOutput);
				csvFileToArray(csvOutput);
			};

			fileReader.readAsText(file);
			
		}
	};

	const csvFileToArray = string => {
		const csvHeader = string.slice(0, string.indexOf("\n")).split(",");
		const csvRows = string.slice(string.indexOf("\n") + 1).split("\n");
		console.log("header: ", csvHeader);
		let myHeader = ["number", "lati1", "long1", "lati2", "long2", "lati3", "long3", "lati4", "long4"];
		const array = csvRows.map(i => {
			const values = i.split(",");
			const obj = myHeader.reduce((object, header, index) => {
				object[header] = Number(values[index]);
				return object;
			}, {});
			return obj;
		});

		console.log(array);

		const requestItem = {
			club_id: clubId,
			box_array: array
		}
	
		console.log("requestItem : ", requestItem);

		adminApi.updateBoxWithCSV(requestItem)
			.then((response) => {

			console.log("box reponse: ");
			console.log(response);
	
			if(response.status === 200){
				helper.showToast('Success', 'Successfully Updated.', 1);
				
				setIsEdit(false);
				setIsRead(false);
	
			}else {
			
				helper.showToast('Error', 'Failed to update box.', 3);
			}
		})
		.catch((error) => {
			console.log("err: ", error);
	
			helper.showToast('Error', 'Invalid club', 3);
		})
	};

	return (
		<>
		<AddClubHeader />
		{/* Page content */}
			<Container className="mt--9" fluid >
				<Row>
					<div className="col">
						<Card className="bg-default shadow">
							<CardHeader className="bg-transparent border-0">
								
								<Row className="align-items-center">
									<div className="col">
										<h3 className="text-white mb-0">{clubName}</h3>
									</div>

									<Form>
										<input
											type={"file"}
											id={"csvFileInput"}
											accept={".csv"}
											onChange={handleOnChange}
										/>

										<button
											onClick={(e) => {
												handleOnSubmit(e);
											}}
										>
											IMPORT CSV
										</button>
									</Form>
								</Row>
							</CardHeader>
							<Table
								className="align-items-center table-dark table-flush"
								responsive
							>
								<thead className="thead-dark">
									<tr>
										<th scope="col">No</th>
										<th scope="col">Latitude1</th>
										<th scope="col">Longitude1</th>
										<th scope="col">Latitude2</th>
										<th scope="col">Longitude2</th>
										<th scope="col">Latitude3</th>
										<th scope="col">Longitude3</th>
										<th scope="col">Latitude4</th>
										<th scope="col">Longitude4</th>
										<th scope="col">  Action  </th>
									</tr>
								</thead>
								<tbody>
								{
									boxList.length > 0 ? 
										boxList.map((item, index) =>
											isEdit && activeIndex == index?
											<tr>
												<th>
													{item.box_number}
												</th>
											
											
												<td>
													<div style={{padding: "0px", width: "100px"}}>
														<Input 
															className="form-control-flush"
															defaultValue={item.lati1}
															onChange={(e) => setLati1(e.target.value)}
															type="number"
														/>
													</div>
												</td>
												<td>
													<div style={{padding: "0px", width: "100px"}}>
														<Input 
															className="form-control-flush"
															defaultValue={item.long1}
															onChange={(e) => setLong1(e.target.value)}
															type="number"
														/>
													</div>
												</td>
												<td>
													<div style={{padding: "0px", width: "100px"}}>
														<Input 
															className="form-control-flush"
															defaultValue={item.lati2}
															onChange={(e) => setLati2(e.target.value)}
															type="number"
														/>
													</div>
												</td>
												<td>
													<div style={{padding: "0px", width: "100px"}}>
														<Input 
															className="form-control-flush"
															defaultValue={item.long2}
															onChange={(e) => setLong2(e.target.value)}
															type="number"
														/>
													</div>
												</td>
												<td>
													<div style={{padding: "0px", width: "100px"}}>
														<Input 
															className="form-control-flush"
															defaultValue={item.lati3}
															onChange={(e) => setLati3(e.target.value)}
															type="number"
														/>
													</div>
												</td>
												<td>
													<div style={{padding: "0px", width: "100px"}}>
														<Input 
															className="form-control-flush"
															defaultValue={item.long3}
															onChange={(e) => setLong3(e.target.value)}
															type="number"
														/>
													</div>
												</td>
												<td>
													<div style={{padding: "0px", width: "100px"}}>
														<Input 
															className="form-control-flush"
															defaultValue={item.lati4}
															onChange={(e) => setLati4(e.target.value)}
															type="number"
														/>
													</div>
												</td>
												<td>
													<div style={{padding: "0px", width: "100px"}}>
														<Input 
															className="form-control-flush"
															defaultValue={item.long4}
															onChange={(e) => setLong4(e.target.value)}
															type="number"
														/>
													</div>
												</td>
												<td>
													<div className="row" >
														<Button
															color="success"
															onClick={(e) => onSave(e)}
															size="sm"
														>
															OK
														</Button>

														<Button
															color="warning"
															onClick={() => onCancel()}
															size="sm"
														>
															Cancel
														</Button>
													</div>
												</td>
											</tr>

											:

											<tr>
												<th>
												{
													item.box_number == 22 ? "AccuPace 11" 
													: 
													item.box_number == 23 ? "AccuCaddie 1"
													:
													item.box_number == 21 ? "AccuPace Exit"
													:
													item.box_number 
												}
												</th>
												<td>{item.lati1}</td>
												<td>{item.long1}</td>
												<td>{item.lati2}</td>
												<td>{item.long2}</td>
												<td>{item.lati3}</td>
												<td>{item.long3}</td>
												<td>{item.lati4}</td>
												<td>{item.long4}</td>
												<td>
													<div className="col">
														<Button
															color="info"
															id={index}
															onClick={(e) => onEdit(e)}
															size="sm"
														>			
															Edit
														</Button>
													</div>
												</td>
											</tr>
										)
									:
									<></>
								}
								</tbody>
							</Table>
						</Card>
					</div>
				</Row>
			</Container>
		</>
	);
};

export default EditBox;