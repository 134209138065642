import React, {useState, useEffect, useCallback} from 'react';
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  Container,
  Row,
  Col,
} from "reactstrap";

import { useHistory } from 'react-router-dom';
import Select from 'react-select'

// core components
import AddClubHeader from "../../components/Headers/AddClubHeader.js";
import environments from "../../environments";

import helper from '../../services/helper.service';
import adminApi from '../../services/admin.service';
  
  function EditGroup (props)  {

    const history = useHistory();
    const [clubId, setClubId] = useState(0);
    const [some2, setSome2] = useState("");
    const [some3, setSome3] = useState("");


    const [isRead, setIsRead] = useState(false);

    useEffect(() => {
       
        const { state } = props.location;
        console.log("club>>: ", state.club);
        const club = state.club;
        if(!isRead){
            setIsRead(true);
            setClubId(club.clubId);
            setSome2(club.some2);
            setSome3(club.some3);

        }
       
    }, [])

    const submitUpdateClub = async() => {

      const updatedClub = {
        club_id: clubId,
        some2: some2,
        some3: some3,
      }

      console.log("updatedClub: ", updatedClub);

      adminApi.updateGroup(updatedClub)
          .then((response) => {

          console.log("Update Group reponse: ");
          console.log(response);
  
          if(response.status === 200){
              helper.showToast('Success', 'Successfully Added.', 1);
              history.push('/');
  
          }else {
          
              helper.showToast('Error', 'Failed to add new club.', 3);
          }
      })
      .catch((error) => {
          console.log("err: ", error);
    
          helper.showToast('Error', 'Invalid club', 3);
      })
  }
    return (
      <>
        <AddClubHeader/>
        {/* Page content */}
        <Container className="mt--9" fluid>
          <Row>

            <Col className="order-xl-1" xl="12">
              <Card className="bg-secondary shadow">
                <CardHeader className="bg-white border-0">
                  <Row className="align-items-center">
                    <Col xs="8">
                      <h3 className="mb-0">Edit Goup Size</h3>
                    </Col>
                    <Col className="text-right" xs="4">
                      <Button
                        color="primary"
                   
                        onClick={() =>submitUpdateClub()}
                        size="sm"
                      >
                        Update
                      </Button>
                    </Col>
                  </Row>
                </CardHeader>
                <CardBody>
                  <Form>
                    <h6 className="heading-small text-muted mb-4">
                      Group Size
                    </h6>
                    <div className="pl-lg-4">

                      <Row>
                        <Col lg="3">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-username"
                            >
                              2 - Some Group
                            </label>
                            <Input
                              className="form-control-alternative"
                              defaultValue={some2}
                              placeholder="72"
                              onChange={(e) => setSome2(e.target.value)}
                              type="number"
                            />
                          </FormGroup>
                        </Col>
                        <Col lg="3">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-email"
                            >
                              3 - Some Group
                            </label>
                            <Input
                              className="form-control-alternative"
                              defaultValue={some3}
                              placeholder="90"
                              type="number"
                              onChange={(e) => setSome3(e.target.value)}
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                 
                    </div>
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </>
    );
  };
  
  export default EditGroup;
  