// reactstrap components
import { Card, CardBody, CardTitle, Container, Row, Col } from "reactstrap";

const CustomerHeader = () => {
  return (
    <>
        <div
            className="header pb-8 pt-5 pt-lg-8 d-flex align-items-center"
            style={{
            minHeight: "100px",
            backgroundImage:
                "url(" +
                require("../../assets/img/theme/admin-cover.jpg") +
                ")",
            backgroundSize: "cover",
            backgroundPosition: "center top",
            }}
        >
            {/* Mask */}
            <span className="mask bg-gradient-default opacity-5" />
        
        </div>
    </>
  );
};

export default CustomerHeader;
